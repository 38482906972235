import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//-- static imports page components
import RouterRoute from "../routerRoutes/routerRoutes";
//import { SuspenseFallback } from "./components/SuspenseFallback";
import SignupPage from "../../pages/signup/SignupPage";
import LoginPage from "../../pages/login/LoginPage";
import RestorePasswordPage from "../../pages/restore-password/RestorePasswordPage";
import OrgchartPage from "../../pages/orgchart/OrgchartPage";
import EmployeePage from "../../pages/employee/EmployeePage";
import EmployeeIndividualPage from "../../pages/employee/EmployeeIndividualPage";
import EmployeeEditPage from "../../pages/employee/EmployeeEditPage";
import SettingsCommonPage from "../../pages/settings/SettingsCommonPage";
import SettingsCompanyPage from "../../pages/settings/SettingsCompanyPage";
import SettingsProfilePage from "../../pages/settings/SettingsProfilePage";
import CommunicationPage from "../../pages/communication/CommunicationPage";
import DriverPage from "../../pages/driver/DriverPage";
import DriverUserPage from "../../pages/driver/DriverUserPage";
import DriverPrePage from "../../pages/driver/DriverPrePage";
import DriverAdminStatisticsPage from "../../pages/driver/DriverAdminStatisticsPage";
import DriverAdminHistoryPage from "../../pages/driver/DriverAdminHistoryPage";
import SandboxPage from "../../pages/sandbox/SandboxPage";
import SandboxPageAY from "../../pages/sandbox/SandboxPageAY";
import SandboxPageYS from "../../pages/sandbox/SandboxPageYS";
import SandboxPageDR from "../../pages/sandbox/SandboxPageDR";
import CompaniesManagementPage from "../../pages/companies-management/CompaniesManagementPage";
import EducationPage from "../../pages/education/EducationPage";
import ReportCommunicationPage from "../../pages/report/ReportCommunicationPage";
import ReportDriverPage from "../../pages/report/ReportDriverPage";
import PlanningPage from "../../pages/planning/PlanningPage";
import RegulationsPage from "../../pages/regulation/RegulationsPage";
import SchedulePage from "../../pages/schedule/SchedulePage";
import GroupCompany from "../../pages/group-company/GroupCompany";
import UpdateNoteListPage from "../../pages/update-note/UpdateNoteListPage";
import UpdateNotePage from "../../pages/update-note/UpdateNotePage";
import UpdateNoteCreatePage from "../../pages/update-note/UpdateNoteCreatePage";
import EditContent from "../../pages/editContent/EditContent";
import UiPage from "../../pages/service/ui/UiPage";
import NoPagesPage from "../../pages/service/no-pages/NoPagesPage";
import Error404Page from "../../pages/error404/Error404Page";
//import ProjectPage from "../../pages/project/ProjectPage";
//import ProjectListPage from "../../pages/project/ProjectListPage";
import DashboardPage from "../../pages/dashboard/DashboardPage";
import SettingsModulesPage from "../../pages/settings/SettingsModulesPage";
import FeedbackPage from "../../pages/feeedback/FeedbackPage";
import { DevPage } from "../../pages/dev/DevPage";
import { InfoPage } from "../../pages/info/InfoPage";
import { PrivacyCookiePage } from "../../pages/privacy-cookie/PrivacyCookiePage";

//-- dynamic imports page components
//const SignupPage = lazy(() => import("../../pages/signup/SignupPage"));
//const LoginPage = lazy(() => import("../../pages/login/LoginPage"));
//const RestorePasswordPage = lazy(() => import("../../pages/restore-password/RestorePasswordPage"));
//const OrgchartPage = lazy(() => import("../../pages/orgchart/OrgchartPage"));
//const EmployeePage = lazy(() => import("../../pages/employee/EmployeePage"));
//const EmployeeIndividualPage = lazy(() => import("../../pages/employee/EmployeeIndividualPage"));
//const EmployeeEditPage = lazy(() => import("../../pages/employee/EmployeeEditPage"));
//const SettingsCommonPage = lazy(() => import("../../pages/settings/SettingsCommonPage"));
//const SettingsCompanyPage = lazy(() => import("../../pages/settings/SettingsCompanyPage"));
//const SettingsProfilePage = lazy(() => import("../../pages/settings/SettingsProfilePage"));
//const CommunicationPage = lazy(() => import("../../pages/communication/CommunicationPage"));
//const DriverPage = lazy(() => import("../../pages/driver/DriverPage"));
//const DriverUserPage = lazy(() => import("../../pages/driver/DriverUserPage"));
//const DriverPrePage = lazy(() => import("../../pages/driver/DriverPrePage"));
//const DriverAdminStatisticsPage = lazy(() => import("../../pages/driver/DriverAdminStatisticsPage"));
//const DriverAdminHistoryPage = lazy(() => import("../../pages/driver/DriverAdminHistoryPage"));
//const SandboxPage = lazy(() => import("../../pages/sandbox/SandboxPage"));
//const SandboxPageAY = lazy(() => import("../../pages/sandbox/SandboxPageAY"));
//const SandboxPageDR = lazy(() => import("../../pages/sandbox/SandboxPageDR"));
//const SandboxPageYS = lazy(() => import("../../pages/sandbox/SandboxPageYS"));
//const CompaniesManagementPage = lazy(() => import("../../pages/companies-management/CompaniesManagementPage"));
//const EducationPage = lazy(() => import("../../pages/education/EducationPage"));
//const ReportCommunicationPage = lazy(() => import("../../pages/report/ReportCommunicationPage"));
//const ReportDriverPage = lazy(() => import("../../pages/report/ReportDriverPage"));
//const PlanningPage = lazy(() => import("../../pages/planning/PlanningPage"));
//const RegulationsPage = lazy(() => import("../../pages/regulation/RegulationsPage"));
//const SchedulePage = lazy(() => import("../../pages/schedule/SchedulePage"));
//const GroupCompany = lazy(() => import("../../pages/group-company/GroupCompany"));
//const UpdateNoteListPage = lazy(() => import("../../pages/update-note/UpdateNoteListPage"));
//const UpdateNotePage = lazy(() => import("../../pages/update-note/UpdateNotePage"));
//const UpdateNoteCreatePage = lazy(() => import("../../pages/update-note/UpdateNoteCreatePage"));
//const EditContent = lazy(() => import("../../pages/editContent/EditContent"));
//const UiPage = lazy(() => import("../../pages/service/ui/UiPage"));
//const NoPagesPage = lazy(() => import("../../pages/service/no-pages/NoPagesPage"));
//const Error404Page = lazy(() => import("../../pages/error404/Error404Page"));
//const ProjectPage = lazy(() => import("../../pages/project/ProjectPage"));
//const ProjectListPage = lazy(() => import("../../pages/project/ProjectListPage"));
//const DashboardPage = lazy(() => import("../../pages/dashboard/DashboardPage"));
//const SettingsModulesPage = lazy(() => import("../../pages/settings/SettingsModulesPage"));
//const FeedbackPage = lazy(() => import("../../pages/feeedback/FeedbackPage"));

interface IRouterRoute {
  id: string;
  path: string;
  content?: JSX.Element;
  pageKey?: string;
  isPrivate?: boolean;
  navigateTo?: string;
}

interface IRouterRoutesAccessLevelsAndPermissions {
  routeId: string;
  // accessLevel - строка. Проверяются вхождения символов
  // a - authorized, u - unauthorized, d - development only
  // Если строка пустая, считается как её отсутствие (Общий доступ)
  accessLevels?: string;
  requiredPermissions?: string;
}

export const routerRoutesArray: IRouterRoute[] = [
  { id: "p.service.home", path: "/", navigateTo: "/login" },
  { id: "p.login", path: "/login", isPrivate: false, content: <LoginPage /> },
  { id: "p.signup", path: "/signup", isPrivate: false, content: <SignupPage /> },
  { id: "p.restorePassword", path: "/restore-password", isPrivate: false, content: <RestorePasswordPage /> },
  // Sandbox Page
  { id: "p.sandbox", path: "/sandbox", content: <SandboxPage />, isPrivate: true },
  { id: "p.sandbox.ay", path: "/sandbox/ay", content: <SandboxPageAY />, isPrivate: true },
  { id: "p.sandbox.dr", path: "/sandbox/dr", content: <SandboxPageDR />, isPrivate: true },
  { id: "p.sandbox.ys", path: "/sandbox/ys", content: <SandboxPageYS />, isPrivate: true },
  // Orgchart Page
  { id: "p.orgchart", path: "/orgchart", content: <OrgchartPage />, isPrivate: true, pageKey: "orgchart" },
  {
    id: "p.orgchart.roleId",
    path: "/orgchart/:roleId",
    content: <OrgchartPage />,
    isPrivate: true,
    pageKey: "orgchart",
  },
  // Employee Page
  { id: "p.employee", path: "/employee", content: <EmployeePage />, isPrivate: true, pageKey: "employee" },
  {
    id: "p.employee.id",
    path: "/employee/:id",
    content: <EmployeeIndividualPage />,
    isPrivate: true,
    pageKey: "employee",
  },
  {
    id: "p.employee.id.edit",
    path: "/employee/:id/edit",
    content: <EmployeeEditPage />,
    isPrivate: true,
    pageKey: "employee",
  },
  // Settings Page
  { id: "p.settings.common", path: "/settings", content: <SettingsCommonPage />, isPrivate: true, pageKey: "settings" },
  {
    id: "p.settings.company",
    path: "/settings/company",
    content: <SettingsCompanyPage />,
    isPrivate: true,
    pageKey: "settings",
  },
  {
    id: "p.settings.profile",
    path: "/settings/profile",
    content: <SettingsProfilePage />,
    isPrivate: true,
    pageKey: "settings",
  },
  {
    id: "p.settings.modules",
    path: "/settings/modules",
    content: <SettingsModulesPage />,
    isPrivate: true,
    pageKey: "settings",
  },
  // Driver Page
  {
    id: "p.driver",
    path: "/driver",
    content: <DriverPrePage />,
    isPrivate: true,
    pageKey: "driver",
  },
  {
    id: "p.driver.next",
    path: "/driver/next",
    content: <DriverPage />,
    isPrivate: true,
    pageKey: "driver",
  },
  {
    id: "p.driver.admin.statistics",
    path: "/driver/admin/statistics",
    content: <DriverAdminStatisticsPage />,
    isPrivate: true,
    pageKey: "driver.admin",
  },
  {
    id: "p.driver.admin.history",
    path: "/driver/admin/history",
    content: <DriverAdminHistoryPage />,
    isPrivate: true,
    pageKey: "driver.admin",
  },
  {
    id: "p.driver.user.id",
    path: "/driver/user/:id",
    content: <DriverUserPage />,
    isPrivate: true,
    pageKey: "driver.user",
  },
  // Driver SA Page
  {
    id: "p.driver.superAdmin",
    path: "/driver/super-admin",
    content: <GroupCompany />,
    isPrivate: true,
    pageKey: "driver.sa",
  },
  {
    id: "p.driver.superAdmin.id",
    path: "/driver/super-admin/:id",
    content: <GroupCompany />,
    isPrivate: true,
    pageKey: "driver.sa",
  },
  {
    id: "p.driver.superAdmin.id.addBot",
    path: "/driver/super-admin/:id/add-bot",
    content: <GroupCompany addBot />,
    isPrivate: true,
    pageKey: "driver.sa",
  },
  // Communication Page
  {
    id: "p.communication",
    path: "/communication",
    content: <CommunicationPage />,
    isPrivate: true,
    pageKey: "communication",
  },
  {
    id: "p.communication",
    path: "/communication/*",
    content: <CommunicationPage />,
    isPrivate: true,
    pageKey: "communication",
  },
  // Companies Management Page
  {
    id: "p.companiesManagement",
    path: "/manage-companies",
    content: <CompaniesManagementPage />,
    isPrivate: true,
    pageKey: "admin.companies_management",
  },
  // Education Page
  { id: "p.education", path: "/education", content: <EducationPage />, isPrivate: true, pageKey: "edu" },
  // Feedback Page
  { id: "p.feedback", path: "/education", content: <FeedbackPage />, isPrivate: true },
  // Report Page
  {
    id: "p.report.communication",
    path: "/report/communication",
    content: <ReportCommunicationPage />,
    isPrivate: true,
    pageKey: "report.communication",
  },
  {
    id: "p.report.driver",
    path: "/report/driver",
    content: <ReportDriverPage />,
    isPrivate: true,
    pageKey: "report.driver",
  },
  // Dashboard Page
  {
    id: "p.dashboard",
    path: "/dashboard",
    content: <DashboardPage />,
    isPrivate: true,
    pageKey: "dashboard",
  },
  {
    id: "p.dashboard.uri",
    path: "/dashboard/:uri",
    content: <DashboardPage />,
    isPrivate: true,
    pageKey: "dashboard",
  },
  // { id: "p.dashboard", path: "/dashboard", content: <DashboardPage /> },
  // Planning Page
  { id: "p.planning", path: "/planning*", content: <PlanningPage />, isPrivate: true, pageKey: "planning" },
  {
    id: "p.planning.workPlans.id",
    path: "/communication/work-plans/:id",
    content: <CommunicationPage />,
    isPrivate: true,
    pageKey: "communication",
  },
  {
    id: "p.planning.approvePlans.id",
    path: "/communication/approve-plan/:id",
    content: <CommunicationPage />,
    isPrivate: true,
    pageKey: "communication",
  },
  // Schedule Page
  { id: "p.schedule", path: "/schedule", content: <SchedulePage />, isPrivate: true, pageKey: "schedule" },
  // Policy Page
  { id: "p.policy", path: "/policy*", content: <RegulationsPage />, isPrivate: true, pageKey: "regulation" },
  // Update Note Page
  {
    id: "p.updateNote",
    path: "/update-note",
    content: <UpdateNoteListPage />,
    isPrivate: true,
    // pageKey: "admin.update_notes",
  },
  {
    id: "p.updateNote.id",
    path: "/update-note/:id",
    content: <UpdateNotePage />,
    isPrivate: true,
    // pageKey: "admin.update_notes",
  },
  {
    id: "p.updateNote.create",
    path: "/update-note/create",
    content: <UpdateNoteCreatePage />,
    isPrivate: true,
    // pageKey: "admin.update_notes",
  },
  // Info Pages
  { id: "p.privacyAndCookiePolicy", path: "/info/privacy-and-cookie", content: <PrivacyCookiePage />, isPrivate: true },
  // { id: "p.cookiePolicy", path: "/info/cookie-policy", content: <CookiePolicyPage />, isPrivate: true },
  // Edit Content Page
  { id: "p.editContent", path: "/edit-content", content: <EditContent />, isPrivate: true },
  // UI-Kit Page
  { id: "p.service.ui", path: "/service/ui", content: <UiPage />, isPrivate: true },
  // Dev Page
  { id: "p.service.dev", path: "/service/dev", content: <DevPage />, isPrivate: true },
  // Info Page
  { id: "p.service.info", path: "/service/info", content: <InfoPage />, isPrivate: true },
  // No Pages Page
  { id: "p.service.noPages", path: "/no-pages", content: <NoPagesPage />, isPrivate: true, pageKey: "service.np" },
  // Error 404 Page
  { id: "p.service.error404", path: "*", content: <Error404Page />, isPrivate: true, pageKey: "service.error404" },
];

const routerRoutesAccessLevelsAndPermissionsArray = [
  {
    routeId: "p.service.error404",
    requiredPermissions: ["asd.asd.asd", "asd.asd.asd"],
    accessLevels: "aud",
  },
];

const disabledRoutesIds: string[] = [];

function AppRouter() {
  const mappedRoutes = routerRoutesArray.map((rR: IRouterRoute) => (
    <Route
      key={rR.id}
      path={rR.path}
      element={
        rR.navigateTo != null ? (
          <Navigate to={rR.navigateTo} />
        ) : (
          <RouterRoute isPrivate={rR.isPrivate} pageKey={rR.pageKey} content={rR.content as JSX.Element} />
        )
      }
    />
  ));
  return (
    // <Router />
    // <BrowserRouter>
    <Routes>{mappedRoutes}</Routes>
    // </BrowserRouter>
  );
}

export default AppRouter;
