import React, { memo, useCallback, useEffect,  useState } from "react";
import InputMask from 'react-input-mask';
import { Icon } from "../../../icon/Icon";
import { LucideClock } from "lucide-react";
import "./TimePickerMask.scss";
import { TimePickerQuickValue } from "./TimePickerQuickValue/TimePickerQuickValue";
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import { IQuickValue, ITimePickerMask } from "./ITimePickerMask";
import clsx from "clsx";


export const TimePickerMask = memo((props: ITimePickerMask) => {
  const [time, setTime] = useState((props.value ?? "").split(':').slice(0, 2).join(':'));

  useEffect(() => {
    const value = (props.value ?? "").split(':').slice(0, 2).join(':');
    setTime(value);
  }, [props.value]);

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue) {
      const [hours = "", minutes = ""] = inputValue.split(':');

      const valueHours = +(hours.replaceAll("_", "")) > 23 ? '23' : hours;
      const valueMinutes = +(minutes.replaceAll("_", "")) > 59 ? '59' : minutes;
      setTime(`${valueHours}:${valueMinutes.length === 1 ? `${valueMinutes}_` : valueMinutes}`);
    }
  };

  const isValidTime = (time: string) => {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };

  useEffect(() => {
    if (time === "__:__" || !time) {
      props.onChange && props.onChange(null);
    }
    if (isValidTime(time) && `${time}:00` !== props.value) {
      props.onChange && props.onChange(`${time}:00`);
    }
  }, [time]);

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsFocus(false);
    }, 150);

  }, []);

  const handleFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleChangeQuickValues = useCallback((value: string) => {
    setTime(value.split(':').slice(0, 2).join(':'));
  }, []);

  const handleClear = useCallback(() => {
    setTime("");
  }, []);

  return (
    <div className={"time-picker-mask-container"}>
      <InputMask
        disabled={props.disabled}
        mask="99:99"
        value={time ?? ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {(inputProps: any) => (

          <div className={clsx("input-wrapper", {
            "input-wrapper_disabled": props.disabled,
          })}>
              <div className="input-wrapper__suffix">
                <Icon component={() => <LucideClock size={16} color="var(--color-gray-base)" />} />
              </div>
              <input
                type="text"
                disabled={props.disabled}
                {...inputProps}
              />
              <div
                className="input-wrapper__clear"
                onClick={handleClear}
              >
                <Icon
                  component={() => <MdCancel size={16} color="var(--color-gray-base)" />}
                />
              </div>
          </div>
        )}
      </InputMask>
      {isFocus && !!props.quickValues?.length && (
        <div className="time-picker-quick-values-list">
          {props.quickValues.map((item: IQuickValue) => (
            <TimePickerQuickValue
              key={item.value}
              item={item}
              onChange={handleChangeQuickValues}
              isActive={item.value === time}
            />
          ))}
        </div>
      )}
    </div>
  )
})