import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueDto, PlanDto } from "../../../../../../api";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { blockedFieldsKeys } from "../../../../forms/types/consts";
import { IPlanningPageMainContent, PlanUpdateStatus } from "./PlanningPageMainContent.interface";
import "./PlanningPageMainContent.scss";
import { PlanningPageMainContentView } from "./PlanningPageMainContentView";
import {
  PatchData
} from "../../components/planningCollapseContent/planningCollapseContentTable/PlanningCollapseContentTable.interface";
import { PlanIssueDto } from "../../../../../../api/models/PlanIssueDto";

export const PlanningPageMainContent = memo(forwardRef((props: IPlanningPageMainContent, ref) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { issueInitDataStore, authStore } = useRootStore();
  const [isOpenPlanRejectDialog, setIsOpenPlanRejectDialog] = useState<boolean>(false);
  const [isOpenApproverDialog, setIsOpenApproverDialog] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const weekReportStart = authStore.getInitialInfo?.identity?.companies?.find(
    (u2c) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
  )?.company?.weekReportStart;
  const restartPlanHistory = useRef<{ restart: () => void }>();

  const [issuesList, setIssuesList] = useState<PlanIssueDto[]>([]);

  useEffect(() => {
    setIssuesList(props.issues);
  }, [props.issues]);

  const handleIsOpenRejectDialogChange = useCallback((value: boolean) => {
    setIsOpenPlanRejectDialog(value);
  }, []);

  const handleUpdatePlanStatus = useCallback(async (status: PlanUpdateStatus) => {
    if (props.plan) {
      const resp = await api.plan.updatePlanStatus(props.plan.id!, status);
      if (resp !== null) {
        const message = status === PlanUpdateStatus.rejected
          ? t("notifier:success.plan_rejected")
          : t("notifier:success.plan_approved")

        console.log("message", message);
        notifier.show({
          message,
          theme: "success",
        });
        props.fetchApprovalPlans && props.fetchApprovalPlans();
        props.fetchPlans && props.fetchPlans();
        restartPlanHistory.current?.restart();
      } else {
        notifier.show({
          message: t("notifier:error.something_wrong"),
          theme: "error",
        });
      }
      console.log("resp", resp);
      setIsLoadingButton(false);

    }
  }, [props.plan?.id])

  // const handlePlanStatusEdit = useCallback(async (plan: PlanDto, message: string) => {
  //   setIsLoadingButton(true);
  //   const r = await api.plan.edit(props.plan?.id!, plan);
  //   if (r) {
  //     notifier.show({
  //       message: message,
  //       theme: "success",
  //     });
  //     props.fetchApprovalPlans && props.fetchApprovalPlans();
  //     props.fetchPlans && props.fetchPlans();
  //     restartPlanHistory.current?.restart();
  //   } else {
  //     notifier.show({
  //       message: t("notifier:error.something_wrong"),
  //       theme: "error",
  //     });
  //   }
  //   setIsLoadingButton(false);
  // }, [restartPlanHistory.current]);

  const handleIssueEdit = useCallback(async (issueId: number, issue: PlanIssueDto, updateData: PatchData) => {
    console.log("handleIssueEdit", updateData);
    setIssuesList((prev) => {
      const indexUpdateIssue = prev.findIndex((item) => item.id === issueId);
      if (indexUpdateIssue !== -1) {
        const list = [...prev];
        list.splice(indexUpdateIssue, 1, issue);
        return list;
      }
      return prev;
    });
    const { path, value} = updateData;
    const resp = await api.issue.editPatch(issueId,   [{
      value,
      path,
      op: "replace"
    }]);
    if (resp) {
      // props.issuesRestart && props.issuesRestart(true);
      props.fetchPlans && props.fetchPlans();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  }, [props.plan?.id]);

  const handleMigrateIssuesToPlan = useCallback(async () => {
    await api.plan.migrateIssues(props.plan?.id!);
    notifier.show({
      message: t("notifier:success.issue_migrated"),
      theme: "success",
    });
    props.fetchPlans && props.fetchPlans();
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    // props.issuesRestart && props.issuesRestart(true);
  }, [props.plan]);

  const handleIssueToPlanCreate = async (issues: (IssueDto | null)[]) => {
    const [issue] = issues;
    if (issue) {
      const status = await props.onAddIssueToPlan(issue.id!);
      if (status) {
        props.issuesFromCommunicationsRestart?.();
        props.fetchPlans?.();
        // props.issuesRestart?.(true);
      }
    }
  };

  const handleIssueCreate = useCallback(() => {
    issueInitDataStore.setOnCreate = handleIssueToPlanCreate;
    issueInitDataStore.setBlockedFields = [blockedFieldsKeys.executorUserId];
    issueInitDataStore.setIssueData({ executorUserId: props.plan?.userId, executorUser: props.plan?.user });
    props.onOpenCreateIssueDialog && props.onOpenCreateIssueDialog();
  }, [ props.plan?.userId, props.plan?.user]);

  const handleIsOpenApproverDialogChange = useCallback((value: boolean) => {
    setIsOpenApproverDialog(value);
  }, []);

  const handleApproverSuccess = useCallback(async (approverId: number | null) => {
    if (props.plan && approverId) {
      const status = await api.plan.submitForApproval(props.plan.id!, approverId);

      if (status !== null) {
        notifier.show({
          message: t("notifier:success.plan_to_approval"),
          theme: "success",
        });

        props.fetchApprovalPlans && props.fetchApprovalPlans();
        props.fetchPlans && props.fetchPlans();
        restartPlanHistory.current?.restart();
      } else {
        notifier.show({
          message: t("notifier:error.something_wrong"),
          theme: "error",
        });
      }
      setIsLoadingButton(false)
    }
    // handlePlanStatusEdit(
    //   { ...props.plan, status: 2, userPublisherId: approverId },
    //   t("notifier:success.plan_to_approval")
    // );

    // const r = await api.plan.edit(props.plan?.id!, plan);
    // if (r) {
    //   notifier.show({
    //     message: message,
    //     theme: "success",
    //   });
    //   props.fetchApprovalPlans && props.fetchApprovalPlans();
    //   props.fetchPlans && props.fetchPlans();
    //   restartPlanHistory.current?.restart();
    // } else {
    //   notifier.show({
    //     message: t("notifier:error.something_wrong"),
    //     theme: "error",
    //   });
    // }
    // setIsLoadingButton(false)

    setIsOpenApproverDialog(false);
  }, [props.plan?.id]);

  // const disabledDate = (current: any) => {
  //   return current && current.toDate() > getWeekStartAndEndByDate(dayjs().add(1, "week"), weekReportStart)[1];
  // };
  //
  // const customWeekStartEndFormat = () => {
  //   const date = props.date && getWeekStartAndEndByDate(props.date, weekReportStart);
  //   return `${dayjs(date && date[0]).format("DD MMM")} - ${dayjs(date && date[1]).format("DD MMM")}`;
  // };

  const handleIssuesRestart = useCallback(() => {
    props.fetchPlans && props.fetchPlans();
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    // props.issuesRestart && props.issuesRestart(true);
  }, [props.plan?.id]);

  useImperativeHandle(ref, () => ({
    restart: restartPlanHistory.current?.restart,
  }));


  return (
    <PlanningPageMainContentView
      ref={restartPlanHistory}
      // onPlanStatusEdit={handlePlanStatusEdit}
      onUpdatePlanStatus={handleUpdatePlanStatus}
      onApproverSuccess={handleApproverSuccess}
      onOpenCreateIssueDialog={props.onOpenCreateIssueDialog}
      isOpen={isOpenPlanRejectDialog}
      onIsOpenChange={handleIsOpenRejectDialogChange}
      plan={props.plan}
      issues={issuesList}
      // scheduleIssues={props.scheduleIssues}
      onLoadRestart={handleIssuesRestart}
      isLoadingButton={isLoadingButton}
      onIssueEdit={handleIssueEdit}
      onIssueToPlanCreate={handleIssueCreate}
      onMigrateIssuesToPlan={handleMigrateIssuesToPlan}
      approvalPlan={props.approvalPlan}
      onPlanIdChange={props.onPlanIdChange}
      isLoading={props.isLoading}
      isOpenApproverDialog={isOpenApproverDialog}
      isOpenApproverDialogChange={handleIsOpenApproverDialogChange}
      isSidebarOpen={props.isSidebarOpen}
      onSidebarOpenChange={props.onSidebarOpenChange}
      isLoadingIssue={props.isLoadingIssue}
      weekReportStart={weekReportStart}
      // issuesRestart={props.issuesRestart}
      userId={props.userId}
      plansShort={props.plansShort}
      currentPlanId={props.currentPlanId}
      isApproval={props.isApproval}
    />
  );
}));
