import React, { memo, useMemo } from "react";
import { Tooltip } from "../../../../../../uiKit";
import { ICustomIssue } from "../PlanningCollapseContent.interface";
import { IPlanningCollapseHeaderProps } from "./PlanningCollapseHeader.interface";
import { useRootStore } from "../../../../../../../hooks";
import { useTranslation } from "react-i18next";
import "./PlanningCollapseHeader.scss";
import { PlaningFilterType } from "../../../../../../../constants/PlaningFilterType";

export const PlanningCollapseHeader = memo((props: IPlanningCollapseHeaderProps) => {
  const { orgchartStore } = useRootStore();
  const { t } = useTranslation();

  const getOrgchartById = (id: number) => orgchartStore.getOrgchartsList.find((o) => o.id == id);

  const tooltipText = useMemo(() => {
    const [ issue] = props.issues;
    return (issue?.orgchartId && getOrgchartById(issue.orgchartId)?.name) ?? ""
  }, [props.issues]);

  const visibility = useMemo(() => {
    return props.issues.find((item: ICustomIssue) => item?.role?.name)?.role?.name ? "visible" : "hidden";
  }, [props.issues]);

  const background = useMemo(() => {
    const [ issue] = props.issues;
    const { orgchartId } = issue ?? {};
    return  (orgchartId &&
    getOrgchartById(orgchartId)?.colorHex !== undefined &&
    (getOrgchartById(orgchartId)?.colorHex ?? "").trim().length > 0
      ? orgchartId && getOrgchartById(orgchartId)?.colorHex
      : "var(--color-layout-fill-base)") ?? "var(--color-layout-fill-base)"
  }, [props.issues]);

  const name = useMemo(() => {
    if (props.name) {
      return props.name;
    }
    return props.groupingType === PlaningFilterType.roles
      ? t("common:planning.no_function")
      : t("common:planning.no_tag")
  }, [props.name, props.groupingType]);

  return (
    <div className="planning-collapse-header d-stack-row align-center justify-start">
      <Tooltip title={tooltipText}>
        <div
          className="planning-collapse-header__icon"
          style={{
            visibility,
            background,
          }}
        />
      </Tooltip>
      { name }
    </div>
  )
})